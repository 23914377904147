import Image from 'next/image'
import styles from './SocialMedia.module.scss'

//import icons
import Facebook from '/public/icons/facebook-icon.svg'
import Instagram from '/public/icons/instagram-icon.svg'
import Youtube from '/public/icons/youtube-icon.svg'
import Linkedin from '/public/icons/linkedin-icon.svg'

export const SocialMedia = () => {
  return (
    <div className={styles.socialMedia}>
      <div className={styles.socialMedia__list}>
        <a
          aria-label="Perfil da Equifax BoaVista no Facebook"
          id="footer-facebook-button"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/equifaxboavista"
        >
          <Image
            src={Facebook}
            loading="lazy"
            alt="Facebook"
            width={8}
            height={16}
          />
        </a>

        <a
          aria-label="Perfil da Equifax BoaVista no instagram"
          href="https://www.instagram.com/equifaxboavista/"
          target="_blank"
          rel="noreferrer"
          id="footer-instagram-button"
        >
          <Image
            src={Instagram}
            loading="lazy"
            alt="Instagram"
            width={16}
            height={16}
          />
        </a>

        <a
          aria-label="Perfil da Equifax BoaVista no Youtube"
          href="https://www.youtube.com/c/BoaVistaServicosOficial"
          target="_blank"
          rel="noreferrer"
          id="footer-youtube-button"
        >
          <Image
            src={Youtube}
            loading="lazy"
            alt="Youtube"
            width={18}
            height={14}
          />
        </a>

        <a
          aria-label="Perfil da Equifax BoaVista no Linkedin"
          href="https://www.linkedin.com/company/boavista"
          rel="noreferrer"
          target="_blank"
          id="footer-linkedin-button"
        >
          <Image
            src={Linkedin}
            loading="lazy"
            alt="Linkedin"
            width={16}
            height={16}
          />
        </a>
      </div>
    </div>
  )
}
