import { CONSUMER_LINKS, NAVIGATION_LINKS } from '../../constants'

import styles from './NavigationLinks.module.scss'

export const NavigationLinks = ({ category, variant, onClick }) => {
  const headerNavigationLinks = {
    consumer: CONSUMER_LINKS,
    business: NAVIGATION_LINKS
  }

  return (
    <nav className={styles[`navigation--${variant}`]}>
      {headerNavigationLinks[category].map(({ id, label, path, target }) => (
        <a
          rel="noreferrer"
          href={path}
          target={target}
          id={`${variant}-${id}-link`}
          key={`${variant}-${id}-link`}
          onClick={onClick}
          className={styles.navigation__link}
        >
          {label}
        </a>
      ))}
    </nav>
  )
}
