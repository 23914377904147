/* eslint-disable no-undef */

import {
  GET_CREDIT_CARD_URL,
  KONDUTO_URL,
  POSITIVE_CONSUMER_URL,
  RIGHT_DEAL_URL
} from '@/constants/links'

const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic'

const URL_PARAMETERS = 'utm_placement=header'

export const SECTION_ANCHORS = [
  {
    id: 'plans',
    label: 'Planos Pós-Pago',
    path: '#planos'
  },
  {
    id: 'businessSolutions',
    label: 'Soluções para empresas ',
    path: '#solucoes-empresa'
  },
  {
    id: 'personalSolutions',
    label: 'Soluções para você ',
    path: '#solucoes-pessoa'
  },
  { id: 'help', label: 'Ajuda ', path: '#ajuda' }
  // {
  //   id: 'consultCnpj',
  //   label: 'Consulte seu CNPJ grátis',
  //   path: `${basePath}formulario/consultar`
  // }
]

export const ORGANIC_BUSINESS_LINKS = [
  {
    id: 'plans',
    label: 'Planos Pós-Pago',
    path: `${urlEquifaxOrganic}/planos/`
  },
  {
    id: 'prepaid',
    label: 'Recarga Pré-Pago',
    path: `${urlEquifaxOrganic}/consultas-pre-pago/`
  },
  {
    id: 'solutions',
    label: 'Soluções',
    path: `${urlEquifaxOrganic}/solucoes-para-empresas/`
  },
  {
    id: 'antifraud',
    target: '_blank',
    label: 'Antifraude',
    path: KONDUTO_URL
  }
  // {
  //   id: 'consultCnpj',
  //   label: 'Consulte seu CNPJ grátis',
  //   path: `${basePath}formulario/consultar`
  // }
]

export const CONSUMER_LINKS = [
  {
    id: 'score',
    target: '_blank',
    label: 'Monitorar score',
    path: `${POSITIVE_CONSUMER_URL}${URL_PARAMETERS}`
  },
  {
    id: 'negotiateDebts',
    target: '_blank',
    label: 'Negociar dívidas',
    path: `${RIGHT_DEAL_URL}${URL_PARAMETERS}`
  },
  {
    id: 'positiveConsumer',
    target: '_blank',
    label: 'Consultar CPF',
    path: `${POSITIVE_CONSUMER_URL}${URL_PARAMETERS}`
  },
  {
    id: 'getLoan',
    target: '_blank',
    label: 'Buscar empréstimo',
    path: `${GET_CREDIT_CARD_URL}${URL_PARAMETERS}`
  }
]

export const NAVIGATION_LINKS = isOrganic
  ? ORGANIC_BUSINESS_LINKS
  : SECTION_ANCHORS
